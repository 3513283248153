.card-container {
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .header {
    border-bottom: 1px solid #e9e9e9;
  }
}
