.button-container {
  padding: 0.5rem;
  background-color: #3bc4ff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background-color: #06b1fb;
  }
}
