.recipe-card-container {
  padding-top: 0.5rem;

  .card-row {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem 0.5rem 0.5rem;
    font-size: 14px;

    .card-label {
      font-weight: 700;
    }
  }

  .recipe-divisor {
    border-bottom: 1px solid #e9e9e9;
    height: 0px;
    padding: 0.5rem 0;
  }

  .saved-money {
    padding-top: .5rem;
    h1 {
      padding-left: .5rem;
      padding-bottom: 1rem;
    }
  }
}

.main-title {
  font-weight: 700;
  font-size: 22px;
}

.header-container {
  padding: 0.5rem;
  h1 {
    padding-bottom: 2px;
  }

  h2 {
    font-size: 14px;
  }
}
